import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Navbar from '../Nav/index'
import Footer from '../Footer/index';
import image from '../../images/BookBrushImage3D-07_book_Template-small.png'
import dantes from '../../images/Dantesbook.png'
import bgImage from '../../images/DarkTrials_WEBBackground.jpg'



const useStyles = makeStyles({
    root: {
        marginTop: '100px'
      },
      media: {
        maxWidth: '70%',
        height: 'auto',
        margin: '0 auto'
       
      },
      form: {
          margin: '10px'
      },
      formParent: {
        marginTop: '70%'
      }
})



export default function Main() {
   

    function scroll() {
        document.getElementById('footer').scrollIntoView({behavior: 'smooth'});
    }

   

    return (
        <React.Fragment>
            <div>
            <img src={bgImage} id="bg" />
                <Navbar />
                <Container> 
                        <Row className="align-items-center mb-4" style={{marginTop: '100px'}}>
                            <Col lg={5} md={5} className="mt-4 pt-2 pt-sm-0">
                                <div className="position-relative">
                                    <img src={image} className="rounded img-fluid mx-auto d-block mb-2 zoom" alt="" />
                                </div>
                            </Col>

                            <Col lg={7} md={7} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                                                <h1 className="title-heading main-font" style={{color: 'white', fontSize: '5rem'}}>THE DARK TRIALS</h1>
                                                <p className="text-muted" style={{lineHeight: '0'}}>Dark Trials Book I</p>
                                                <p className="text-white h5 secondary-font" style={{lineHeight: '1'}}>The Dark Trials is the exciting first book in The Dark Trials series. </p>
                                            
                                            <p className="text-white h5 secondary-font" style={{lineHeight: '1.5'}}>A YA supernatural thriller, The Dark Trials is full of mystery, suspense, and heart-wrenching twists that will keep you turning the page.</p>

                                        {/* <p className="text-white h6 secondary-font">Buy on <img src="https://assets.website-files.com/5d028ea34d3326779a804753/5d24f2e86ad064459f476cc6_amazon-white.svg" height="32" alt="" class="ml-2 mr-2"></img> or read for free with Kindle Unlimited</p>
                                           <a href="https://www.amazon.com/gp/product/1736270419/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1736270419&linkCode=as2&tag=tjameskelly-20&linkId=6476a92b5407bf226263404ac38ccc03" class="btn btn-outline-light mt-4 mr-4 secondary-font" style={{padding: '10px 30px 10px 30px'}}>Paperback</a>
                                           <a href="https://www.amazon.com/gp/product/B08V179Z85/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B08V179Z85&linkCode=as2&tag=tjameskelly-20&linkId=d3404e73b7cfbc36e5bcd76d6572a289" class="btn btn-outline-light mt-4 secondary-font" style={{padding: '10px 30px 10px 30px'}}>Kindle</a> */}

                            </Col>  
                        </Row> 
                     </Container> 
                    <Container> 
                        <Row className="align-items-center mb-4" style={{marginTop: '100px'}}>
                            <Col lg={5} md={5} className="mt-4 pt-2 pt-sm-0">
                                <div className="position-relative">
                                    <img src={dantes} className="rounded img-fluid mx-auto d-block mb-2 zoom" alt="" />
                                </div>
                            </Col>

                            <Col lg={7} md={7} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                                                <h1 className="title-heading main-font" style={{color: 'white', fontSize: '5rem'}}>DANTES</h1>
                                                <p className="text-muted" style={{lineHeight: '0'}}>Dark Trials Book II</p>
                                                <p className="text-white h5 secondary-font" style={{lineHeight: '1.5'}}>DANTES is the second book in the YA supernatural thriller Dark Trials series. If you like kick-ass female heroines, dark supernatural forces, and heart-jarring twists, then you’ll love T. James Kelly’s page-turning series.</p>
                                            <br />
                                           {/* <p className="text-white h6 secondary-font">Buy on <img src="https://assets.website-files.com/5d028ea34d3326779a804753/5d24f2e86ad064459f476cc6_amazon-white.svg" height="32" alt="" class="ml-2 mr-2"></img> or read for free with Kindle Unlimited</p>
                                           <a href="https://www.amazon.com/Dantes-Book-2-Dark-Trials/dp/1736270435/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=" class="btn btn-outline-light mt-4 mr-4 secondary-font" style={{padding: '10px 30px 10px 30px'}}>Paperback</a>
                                           <a href="https://www.amazon.com/dp/B09G3333MF" class="btn btn-outline-light mt-4 secondary-font" style={{padding: '10px 30px 10px 30px'}}>Kindle</a> */}

                            </Col>  
                        </Row> 
                     </Container>
                    
            
            </div>
            <Footer />
        </React.Fragment>
    )
}