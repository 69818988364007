import React, { useState } from 'react';

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import Navbar from '../Nav/index'
import Footer from '../Footer/index'
import image from '../../images/DarkTrials_WEB.jpg'
import dantes from '../../images/Dantes Ebook Cover.jpg'
import bgImage from '../../images/DarkTrials_WEBBackground.jpg'


export default function Books() {

    return (
        <React.Fragment>
                        <img src={bgImage} id="bg"  />

            <Navbar />

            <Container className="margin-size">
                <Row>
                    <Col lg={3} className="mt-4">
                        <img src={image} className="rounded img-fluid mx-auto d-block mb-2" alt="The Dark Trials" />
                    </Col>
                    <Col lg={9} className="mt-4" style={{display: 'inline-grid'}}>
                        <div style={{lineHeight: 0}}>
                        <h1 className="title-heading main-font" style={{color: 'white'}}>The Dark Trials</h1>
                        <p className="text-muted">Book I</p>
                        </div>

                        <p className=" h4" style={{color: '#f2802c'}}>“When it comes to dying, the first time is the worst.”</p>
                        <p className="text-white h6 secondary-font" style={{lineHeight: '1.5'}}>
                                Hannah Church lost her parents, moved herself and her young sister across the country to live
                                with their deadbeat aunt, and started senior year at a new high school when things made a turn
                                for the strange. Soft taps on her bedpost each morning, objects moving on their own, and that
                                prickly, being-watched feeling are all signs of what Hannah’s afraid to admit: She’s haunted.
                                She’s plagued by something dark, something supernatural, something she doesn’t understand.
                                The only person who seems to have answers is a tall, mysterious, black-clad classmate, but
                                there’s just one problem: Hannah can’t be sure whether he wants to help her or whether he wants
                                her dead.
                                But when Hannah’s supernatural stalker threatens to destroy the one thing she loves most, she
                                turns to him. With life, death, and eternal damnation hanging in the balance, Hannah faces off
                                against the underworldly in a race against time to reclaim what’s hers.</p>

                      
                    </Col>
                </Row>
                {/* <Row>
                    <Col lg={3} className="mt-4">

                    </Col>
                    <Col lg={9}>
                <p className="text-white h6 secondary-font">Buy on <img src="https://assets.website-files.com/5d028ea34d3326779a804753/5d24f2e86ad064459f476cc6_amazon-white.svg" height="32" alt="" class="ml-2 mr-2"></img> or read for free with Kindle Unlimited</p>
                                           <a href="https://www.amazon.com/gp/product/1736270419/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=1736270419&linkCode=as2&tag=tjameskelly-20&linkId=6476a92b5407bf226263404ac38ccc03" class="btn btn-outline-light mt-4 mr-4 secondary-font" style={{padding: '10px 30px 10px 30px'}}>Paperback</a>
                                           <a href="https://www.amazon.com/gp/product/B08V179Z85/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B08V179Z85&linkCode=as2&tag=tjameskelly-20&linkId=d3404e73b7cfbc36e5bcd76d6572a289" class="btn btn-outline-light mt-4 secondary-font" style={{padding: '10px 30px 10px 30px'}}>Kindle</a>
                                           </Col>
                </Row> */}
            <hr />
                <Row>
                    <Col lg={3} className="mt-4">
                        <img src={dantes} className="rounded img-fluid mx-auto d-block mb-2" alt="The Dark Trials" />
                    </Col>
                    <Col lg={9} className="mt-4" style={{display: 'inline-grid'}}>
                        <div style={{lineHeight: 0}}>
                        <h1 className="title-heading main-font" style={{color: 'white'}}>Dantes</h1>
                        <p className="text-muted">Book II</p>
                        </div>

                        <p className=" h4" style={{color: '#f2802c'}}>“Hannah is betrayed. Adam is captured. Now, she must raze Hell to save him…”</p>
                        <p className="text-white h6 secondary-font" style={{lineHeight: '1.5'}}>
                        It’s been nearly one year since Hannah faced the Dark Trials. All she desperately wants now is a normal life for herself and her sister. 
                        But for someone who can shift between worlds and slay the demons that haunt her small town, normal life was never in the cards. When she finally comes to terms with
                         her true feelings, she realizes that her bond with Adam is more significant than she’d ever imagined. After he joins a secretive band of demon hunters,
                         she has a hard choice to make: Should she team up with him and join, too? But when Adam is kidnapped by an ancient enemy, Hannah questions everything she knows 
                         and everyone she trusts. As dark forces combine, she must dive deeper into the underworld—even to Hell itself—in a race against time to rescue him. What she finds 
                         there will change her world forever, and might even destroy it...
                         </p>
                         <p className="text-white h6 secondary-font" style={{lineHeight: '1.5'}}>DANTES is the second book in the YA supernatural thriller Dark Trials series. If you like kick-ass female heroines, dark supernatural forces, and heart-jarring twists, then you’ll love T. James Kelly’s page-turning series.</p>

 

{/* Buy DANTES to continue this exciting new series today!</p> */}

                      
                    </Col>
                </Row>
                {/* <Row>
                    <Col lg={3} className="mt-4">

                    </Col>
                    <Col lg={9}>
                <p className="text-white h6 secondary-font">Buy on <img src="https://assets.website-files.com/5d028ea34d3326779a804753/5d24f2e86ad064459f476cc6_amazon-white.svg" height="32" alt="" class="ml-2 mr-2"></img> or read for free with Kindle Unlimited</p>
                                           <a href="https://www.amazon.com/Dantes-Book-2-Dark-Trials/dp/1736270435/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr=" class="btn btn-outline-light mt-4 mr-4 secondary-font" style={{padding: '10px 30px 10px 30px'}}>Paperback</a>
                                           <a href="https://www.amazon.com/dp/B09G3333MF" class="btn btn-outline-light mt-4 secondary-font" style={{padding: '10px 30px 10px 30px'}}>Kindle</a>
                                           </Col>
                </Row> */}
            </Container>
            
            <Footer />
        </React.Fragment>
    )
}